import Blog1 from '../Images/blog1.jpg';
import Blog2 from '../Images/blog2.jpg';
import Blog3 from '../Images/blog3.jpg';

const BlogData = [
    {
        id: 1,
        title: 'Humanitarian Aids',
        introduction: "Humanitarian aid is assistance provided to people in need, often during emergencies such as natural disasters, conflicts, or other crises. It is guided by principles of humanity, neutrality, impartiality, and independence, with the aim of saving lives, reducing suffering, and preserving human dignity.",
        point1Title: "Types of Humanitarian Aid:",
        bulletpoint1: [
            {
                point: "Emergency Relief: Immediate assistance provided in response to disasters such as earthquakes, floods, or conflict-related emergencies. This includes providing food, water, shelter, medical care, and other essential supplies to affected populations."
            },
            {
                point: "Rehabilitation and Recovery: Support provided to help communities recover and rebuild after a crisis. This may include rebuilding infrastructure, restoring livelihoods, and providing psychosocial support to those affected.",
            },
            {
                point: "Rehabilitation and Recovery: Support provided to help communities recover and rebuild after a crisis. This may include rebuilding infrastructure, restoring livelihoods, and providing psychosocial support to those affected.",
            }
        ],
        point2Title: "Impact of Humanitarian Aid:",
        bulletpoint2: [
            {
                point: "Saving Lives: Humanitarian aid provides lifesaving assistance to people affected by emergencies, including food, water, shelter, and medical care, which can mean the difference between life and death.",
            },
            {
                point: "Alleviating Suffering: Aid helps alleviate the suffering of those affected by crises by providing essential services and support, restoring a sense of normalcy, and promoting resilience.",
            },
            {
                point: "Building Resilience: By providing support to rebuild infrastructure, restore livelihoods, and strengthen communities' ability to cope with future crises, humanitarian aid helps build resilience and reduce vulnerability."
            },
        ],
        conclusion: "Humanitarian aid is a powerful tool for saving lives, alleviating suffering, and building resilience in communities affected by crises and disasters. It is a testament to our common humanity and our shared responsibility to help those in need. By supporting humanitarian efforts and working together, we can make a difference in the lives of millions of people around the world.",
        dateDay: '23',
        dateMonth: 'July',
        image: Blog3
    },
    {
        id: 2,
        title: 'Nurturing the Future',
        introduction: "Child healthcare is a fundamental aspect of ensuring the well-being and development of our future generations. From infancy to adolescence, children require specialized care to support their growth, prevent illnesses, and address health challenges. In this blog, we will explore the importance of child healthcare, key aspects of child health, and strategies to promote the health and well-being of children.",
        point1Title: "The Importance of Child Healthcare:",
        bulletpoint1: [
            {
                point: "Early Detection and Prevention: Regular check-ups and screenings can help detect health issues early, allowing for timely intervention and prevention of potential complications."
            },
            {
                point: "Growth and Development: Proper nutrition, immunizations, and developmental screenings are essential for ensuring healthy growth and development in children.",
            },
            {
                point: "Disease Prevention: Vaccinations play a crucial role in preventing childhood illnesses such as measles, polio, and whooping cough, protecting both individual children and the community as a whole."
            },
            {
                point: "Behavioral and Mental Health: Addressing behavioral and mental health issues early can improve outcomes and prevent long-term problems."
            },
            {
                point: "Education and Support: Providing parents and caregivers with education and support on topics such as nutrition, safety, and child development can help promote the overall health and well-being of children."
            }
        ],
        point2Title: "Strategies to Promote Child Health:",
        bulletpoint2: [
            {
                point: "Access to Healthcare: Ensure that children have access to regular healthcare services, including well-child visits, vaccinations, and screenings.",
            },
            {
                point: "Education and Awareness: Educate parents, caregivers, and communities about the importance of child health and how to promote it.",
            },
            {
                point: "Supportive Environments: Create environments that support healthy behaviors, such as access to nutritious foods and safe places to play."
            },
            {
                point: "Collaboration: Work collaboratively with healthcare providers, schools, and community organizations to promote child health and address health disparities."
            }
        ],
        conclusion: "Investing in child healthcare is crucial for ensuring the health and well-being of current and future generations. By prioritizing child health, we can help children reach their full potential and build healthier communities. Let us work together to nurture the health of our children and secure a healthier future for all.",
        dateDay: '28',
        dateMonth: 'Aug',
        image: Blog1
    },
    {
        id: 3,
        title: 'Our Environment State',
        introduction: "The environment is a complex and interconnected system that sustains all life on Earth. Over the past few decades, there has been growing concern about the health of our environment due to human activities. In this blog, we will delve into the current state of our environment, analyze key environmental issues, and discuss potential solutions to protect and preserve our planet for future generations.",
        point1Title: "Overview of Environmental Issues:",
        bulletpoint1: [
            {
                point: "Climate Change: One of the most pressing environmental issues of our time, climate change is primarily driven by the increase in greenhouse gases such as carbon dioxide (CO2) due to human activities like burning fossil fuels and deforestation. This has led to rising global temperatures, extreme weather events, and sea-level rise.",
            },
            {
                point: "Deforestation: The clearing of forests for agriculture, logging, and urbanization has detrimental effects on biodiversity, carbon sequestration, and local ecosystems. Deforestation contributes significantly to greenhouse gas emissions and threatens the habitats of many species."
            },
            {
                point: "Loss of Biodiversity: Human activities such as habitat destruction, overexploitation of resources, pollution, and climate change are driving species extinction at an alarming rate. Loss of biodiversity has far-reaching consequences for ecosystem stability and human well-being.",
            },
            {
                point: "Water Scarcity: Water scarcity is becoming increasingly prevalent due to population growth, overuse of water resources, pollution, and climate change. Access to clean and safe drinking water is essential for human health and sustainable development."
            }
        ],
        point2Title: "Solutions for a Sustainable Future:",
        bulletpoint2: [
            {
                point: "Transition to Renewable Energy: Phasing out fossil fuels in favor of renewable energy sources such as solar, wind, and hydropower can significantly reduce greenhouse gas emissions and mitigate climate change.",
            },
            {
                point: "Sustainable Land Use Practices: Adopting sustainable agricultural practices, reforestation, and protecting natural habitats can help conserve biodiversity, enhance carbon sequestration, and restore degraded ecosystems.",
            },
            {
                point: "Pollution Control Measures: Implementing stricter regulations on industrial emissions, promoting waste reduction and recycling, and cleaning up polluted sites can help reduce pollution and protect human and environmental health.",
            },
            {
                point: "Conservation and Restoration Efforts: Investing in conservation efforts, protected areas, and species recovery programs can help preserve biodiversity and restore ecosystems that have been degraded or destroyed.",
            }
        ],
        conclusion: "The health of our environment is intricately linked to the well-being of all life on Earth. By understanding the current state of our environment, identifying key environmental issues, and implementing solutions to address them, we can work towards a more sustainable future. It is crucial that individuals, communities, governments, and businesses come together to take action and protect our planet for generations to come.",
        dateDay: '12',
        dateMonth: 'Oct',
        image: Blog2
    },
]
export default BlogData