import React from 'react'
import './Footer.scss';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaPhoneAlt } from "react-icons/fa";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { IoLogoDropbox } from "react-icons/io";
import Logo from '../../Images/Logo.png'

function Footer() {
    return (
        <div className='footerContainer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <div className='contentWrapper'>
                            <div className='logoSection'><img src={Logo} alt="" /></div>
                            <div className='content'>
                                <div className="text">The secret to happiness lies
                                    in helping others. Never underestimate the
                                    difference YOU can make in the lives of the poor
                                </div>
                                <div className='socialUrl'>
                                    <div className='social-link'> <a href="https://facebook.com"><FaFacebookF className='icon' /></a></div>
                                    <div className='social-link'> <a href="https://facebook.com"><FaLinkedinIn className='icon' /></a></div>
                                    <div className='social-link'> <a href="https://facebook.com"><FaTwitter className='icon' /></a></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <div className='contentWrapper'>
                            <div className='title'>Quick Links</div>
                            <div className='content'>
                                <div className='dropIcon'><IoLogoDropbox className='icon' />
                                    <NavLink to='/about' className='footer-nav'>About</NavLink>
                                </div>
                                <div className='dropIcon'><IoLogoDropbox className='icon' />
                                    <NavLink to='/blog' className='footer-nav'>Blog</NavLink>
                                </div>

                                <div className='dropIcon'><IoLogoDropbox className='icon' />
                                    <NavLink to='/contact' className='footer-nav'>Contact</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <div className='contentWrapper'>
                            <div className='title'>Contact</div>
                            <div className='content'>
                                <div className='contact'>
                                    <div className='icondiv'><MdLocationOn className="icon" /> </div>
                                    <div className='name'>No 10 Wiche Lane 1 Off Uniport Road Ndudor Choba Port Harcourt</div>
                                </div>
                                <div className='contact'>
                                    <div className='icondiv'><FaPhoneAlt className="icon" /> </div>
                                    <div className='name'>Support: 0803 544 1455 or 07088879779</div>
                                </div>
                                <div className='contact'>
                                    <div className='icondiv'><MdEmail className="icon" /> </div>
                                    <div className='name'>Email: <a href="mailto:beulahhealthforlife@gmail.com">Beulahhealthforlife@gmail.com</a> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer