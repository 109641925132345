import React from 'react';
import './About.scss'
import BannerComponent from '../../Components/BannerComponent/BannerComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import speaker from '../../Images/aboutSpeaker.jpg';
import { SiMicrosoftvisio, SiMicrosoftsharepoint } from "react-icons/si";
import { GiMissileLauncher } from "react-icons/gi";
import bigImage from '../../Images/about2.jpg';
import smallImage from '../../Images/about1.jpg'
import ContentCard from '../../Components/contentCard/ContentCard';

function About() {
    return (
        <div>
            <BannerComponent
                pageTitle="About Us"
                pageName="About us"
            />

            <div className='AboutPageContainer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className="imageSession">
                                <div className='bigImage'>
                                    <img src={bigImage} alt="" />
                                </div>
                                <div className='smallImage'>
                                    <img src={smallImage} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-7'>
                            <PageHeader
                                title="Impact of"
                                titlespan="BeulahHealth"
                            />
                            <div className='aboutText'>
                                Beulah Health for Life initiative is a community based organization (CBO)
                                that works at the local level whose objective is to promote and to improve
                                the wellbeing of the members of the community.
                                We are focussed on health volunteer counselling, bringing health
                                actualization and screening to members of the community, rural
                                health outreaches for everyone in the community, safe water,
                                hygiene and environmental safety.
                            </div>
                            <div className='mission'>
                                <div className='first'>
                                    <SiMicrosoftvisio className='icon' />
                                </div>
                                <div className='second'>
                                    <div className='title'>Our Vision</div>
                                    <div className='text'>
                                        To improve the wellbeing of individuals in rural communities and
                                        make them free from infectious diseases and highly contaminated
                                        environment through health education, free medical outreaches and
                                        safety practices.
                                    </div>
                                </div>
                            </div>

                            <div className='mission'>
                                <div className='first'>
                                    <GiMissileLauncher className='icon' />
                                </div>
                                <div className='second'>
                                    <div className='title'>Our Mission</div>
                                    <div className='text'>
                                        To bring to the knowledge of individuals in rural communities the
                                        need for good health practices, carry out health campaigns, Medical
                                        outreaches and safety programmes.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='bannerSession'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='image' style={{ marginBottom: '20px' }}>
                                    <img src={speaker} alt="" />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='mission'>
                                    <div className='aboutList'>
                                        <div className='pageSubTHeading'>
                                            Our Programmes include but not limited to the following:
                                        </div>

                                        <div className='listItem'>
                                            <span><SiMicrosoftsharepoint className='icon' /></span> Health talks
                                        </div>
                                        <div className='listItem'>
                                            <span><SiMicrosoftsharepoint className='icon' /></span> HIV counselling and testing in communities
                                        </div>
                                        <div className='listItem'>
                                            <span><SiMicrosoftsharepoint className='icon' /></span>Medical outreaches in rural communities
                                        </div>
                                        <div className='listItem'>
                                            <span><SiMicrosoftsharepoint className='icon' /></span> Screening and testing for Tuberculosis in children and adults
                                        </div>
                                        <div className='listItem'>
                                            <span><SiMicrosoftsharepoint className='icon' /></span> Nutrition, Disease surveillance, Roll back Malaria, Breastfeeding
                                        </div>
                                        <div className='listItem'>
                                            <span><SiMicrosoftsharepoint className='icon' /></span>Deworming and Immunization.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* content card session */}
                <ContentCard />
            </div>
        </div>
    )
}

export default About