import React from 'react';
import './HomeEvent.scss';
import eventImage from '../../Images/upcommingevent.jpg'
import { eventData } from '../../Pages/Data/EventData';
import { MdOutlineAccessTimeFilled, MdLocationPin } from "react-icons/md";

function HomeEvent() {
    return (
        <div className='HomeEventContainer'>
            <div className='imageSession'>
                <img src={eventImage} alt="" />
            </div>
            <div className='contentContainer'>
                <div className='container'>
                    <div className='pageSubTHeading'>
                        A Health Charity with Mission
                    </div>
                    <div className="pageTitle">Upcoming <span>Events</span></div>

                    <div className='subText'>
                        The secret to happiness lies in helping others. Never underestimate the
                        difference YOU can make in the lives of the poor, the abused and the helpless.
                    </div>

                    <div className='row'>
                        {eventData.sort((a, b) => (b.id) - (a.id)).slice(0, 2).map((data, index) => (
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='eventContainer' key={index}>
                                    <div className='date'>25 <span>July</span></div>
                                    <div className='moderator'>Organized By: <span>Beulah Health</span></div>
                                    <div className='title'>{data.title}</div>
                                    <div className='timeLocation'>
                                        <div className='first'>
                                            <MdOutlineAccessTimeFilled className='icon' />
                                            Starts 5.00PM
                                        </div>
                                        <div className='second'>
                                            <MdLocationPin className='icon' />
                                            {data.location}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeEvent