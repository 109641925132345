import React from 'react'
import { NavLink } from 'react-router-dom'
import { IoIosLink } from "react-icons/io";
import { MdOutlinePerson3 } from "react-icons/md";
import './BlogComponent.scss';

function BlogComponent({ data, page }) {
    return (
        <div className='BlogCard'>
            <div className='inner-box'>
                <div className={page === 'Homepage' ? 'image' : page === 'BlogDetail' ? ' image fullImage' : 'image'}>
                    <img src={data.image} alt="" />
                    <div className='overlay'>
                        <NavLink to={`/blog/${(data.title).replace(/\s+/g, '-')}`}  ><div className='link'><IoIosLink className='icon' /></div></NavLink>
                    </div>
                </div>

                {
                    page === 'Homepage' ?
                        <div className='lower-box'>
                            <div className='date'>
                                <div className='day'>{data.dateDay}</div>
                                <div className='month'>{data.dateMonth}</div>
                            </div>
                            <div className='otherContent'>
                                <div className='postMeta'>By: <span>BeulahHealth</span></div>
                                <div className='title'>{data.title}</div>
                                <div className='text'>{data.introduction}</div>
                                <div className='readMore'>
                                    <NavLink to={`/blog/${(data.title).replace(/\s+/g, '-')}`} className='read' >Read More</NavLink>
                                </div>
                            </div>
                        </div> :
                        <div className={page === 'BlogDetail' ? 'lower-box lower-boxMainBlog' : 'lower-box lower-boxMainBlog lowerboxMopadding'}>
                            <div className='top'>
                                <div className='date'>
                                    <div className='day'>{data.dateDay}</div>
                                    <div className='month'>{data.dateMonth}</div>
                                </div>
                                <div className='title'>
                                    {data.title}
                                    <div className='posterName'><span><MdOutlinePerson3 className='icon' /></span> By: BeulahHealth</div>
                                </div>
                            </div>

                            <div className={page === 'BlogDetail' ? 'text  fulltext' : 'text'}>{data.introduction}</div>
                            {page !== 'BlogDetail' ?
                                <div className='readMore'>
                                    <NavLink to={`/blog/${(data.title).replace(/\s+/g, '-')}`} className='read' >Read More</NavLink>
                                </div>
                                : ""
                            }

                            {
                                page === 'BlogDetail' && (
                                    <div className='blogDetailContent'>
                                        <div className='text fulltext' style={{ fontWeight: '600' }}>
                                            {data.point1Title}
                                        </div>

                                        {data.bulletpoint1.map((bullet, i) => {
                                            return (
                                                <div className='text fulltext' key={i}>{i + 1}. {bullet.point}</div>
                                            )
                                        })}

                                        <div className='text fulltext' style={{ fontWeight: '600' }}>
                                            {data.point2Title}
                                        </div>

                                        {data.bulletpoint2.map((bullet, i) => {
                                            return (
                                                <div className='text fulltext' key={i}>{i + 1}. {bullet.point}</div>
                                            )
                                        })}

                                        <div className='text fulltext'>
                                            In conclusion, {data.conclusion}
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                }

            </div>
        </div>
    )
}

export default BlogComponent