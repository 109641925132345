import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner1 from '../../Images/banner1.jpg'
import banner2 from '../../Images/banner2.jpg'
import banner3 from '../../Images/banner3.jpg'
import banner4 from '../../Images/banner4.jpg'
import './Carousel.scss'
import { NavLink } from 'react-router-dom';
// import './owl.css'; 

function Carousel() {
    return (
        <div>
            <div className='pageContainer' >
                <OwlCarousel items={1}
                    className="owl-carousel owl-theme"
                    loop
                    nav
                    margin={8}
                    dots={false}
                    mouseDrag={false}
                    autoplay
                    autoplayTimeout={8000}
                    animateOut={'animate__animated animate__fadeInUpBig'}
                >
                    <div className='sliderWrapper'>
                        <img alt="img" className="img" src={banner1} />
                        <div className="slider-overlay"></div>
                        <div className="cover">
                            <div className="container">
                                <div className="header-content">
                                    <div className="line"></div>
                                    <h3>Change the life, Change the world
                                        <span className='border-shape'></span>
                                    </h3>
                                    <h1>Contribute to defeat the disease of the world</h1>
                                    <p>With Warmth and true Support</p>
                                    <NavLink to="/about" className='buttonNav'>Learn More</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sliderWrapper'>
                        <img alt="img" className="img" src={banner2} />
                        <div className="cover">
                            <div className="container">
                                <div className="header-content">
                                    <div className="line"></div>
                                    <h3>Change the life, Change the world
                                        <span className='border-shape'></span>
                                    </h3>
                                    <h1>Be part of the global health impact network</h1>
                                    <p>With Warmth and true Support</p>
                                    <NavLink to="/about" className='buttonNav'>Learn More</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sliderWrapper'>
                        <img alt="img" className="img" src={banner3} />
                        <div className="cover">
                            <div className="container">
                                <div className="header-content">
                                    <div className="line"></div>
                                    <h3>Change the life, Change the world
                                        <span className='border-shape'></span>
                                    </h3>
                                    <h1>Sponsor an orphan and feed the poor</h1>
                                    <p>With Warmth and true Support</p>
                                    <NavLink to="/about" className='buttonNav'>Learn More</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sliderWrapper'>
                        <img alt="img" className="img" src={banner4} />
                        <div className="cover">
                            <div className="container">
                                <div className="header-content">
                                    <div className="line"></div>
                                    <h3>Change the life, Change the world
                                        <span className='border-shape'></span>
                                    </h3>
                                    <h1>Together we can make a difference</h1>
                                    <p>With Warmth and true Support</p>
                                    <NavLink to="/about" className='buttonNav'>Learn More</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    )
}

export default Carousel