export const eventData = [
    {
        id: 1,
        title: 'Sports Event for funding charity for innocent people',
        date: '25 July',
        location: 'portharcourt'
    },
    {
        id: 2,
        title: "Visitation to Orphanage Home",
        date: '20 December',
        location: 'portharcourt'
    },
    {
        id: 3,
        title: 'School sensitization on proper medical care',
        date: '30 July',
        location: 'portharcourt'
    },
    {
        id: 4,
        title: 'March Outreach to village',
        date: '15 April',
        location: 'portharcourt Nigeria'
    },
]