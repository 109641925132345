import React from 'react';
import './HomeCauses.scss'
import causes1 from '../../Images/courses1.jpg';
import causes2 from '../../Images/couses2.jpg';
import causes3 from '../../Images/couses3.jpg';
import { NavLink } from 'react-router-dom';
import PageHeader from '../PageHeader/PageHeader';

function HomeCauses() {
    return (
        <div className='homeCausesContainer'>
            <div className='container'>
                <PageHeader
                    title="Our Recent"
                    titlespan='Causes'
                    text="A little help accompanied by a gentle smile adds purpose to our existence as humans"
                    currentPage="Home"
                />

                <div className='HomeCousesContent'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='contentContainer'>
                                <div className='imageContainer'>
                                    <img src={causes1} alt="" />
                                    <div className='overlay'></div>
                                </div>
                                <div className='content'>
                                    <div className='subTitle'>Tuberculosis</div>
                                    <div className='title'>Sensitization on Tuberculosis</div>
                                    <span></span>
                                    <div className='text'>
                                        Embarked on a comprehensive tuberculosis (TB) sensitization campaign to raise awareness about the disease and its prevention.
                                    </div>
                                    <div className='transparentButtonNav'>
                                        <NavLink to="/causes/sensitization_on_tuberculosis" className="buttonNav">Read More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='contentContainer'>
                                <div className='imageContainer'>
                                    <img src={causes2} alt="" />
                                    <div className='overlay'></div>
                                </div>
                                <div className='content'>
                                    <div className='subTitle'>Leprosy</div>
                                    <div className='title'>Sensitization on Leprosy</div>
                                    <span></span>
                                    <div className='text'>
                                        By raising awareness, contribute to improving the lives of individuals affected by leprosy and reducing the burden of the disease in the community.
                                    </div>
                                    <div className='transparentButtonNav'>
                                        <NavLink to="/causes/sensitization_on_leprosy" className="buttonNav">Read More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='contentContainer'>
                                <div className='imageContainer'>
                                    <img src={causes3} alt="" />
                                    <div className='overlay'></div>
                                </div>
                                <div className='content'>
                                    <div className='subTitle'>Medicine</div>
                                    <div className='title'>Free Medical care</div>
                                    <span></span>
                                    <div className='text'>
                                        Provides a valuable service by offering free medical care. This initiative demonstrates a commitment to community health and wellbeing.
                                    </div>
                                    <div className='transparentButtonNav'>
                                        <NavLink to="/causes/free_medical_care" className="buttonNav">Read More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomeCauses