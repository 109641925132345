import React from 'react';
import { FaHandsHelping } from "react-icons/fa";
import './PageHeader.scss';

function PageHeader({ title, titlespan, text, currentPage }) {
    return (
        <div className='pageHeaderContainer'>
            <div className='smallLogoDesign text-center'>
                <span className='firstBefore'></span>
                {currentPage !== 'Home' ?
                    <div className='pageText'>A charity with believe in help</div> :
                    <FaHandsHelping className='icon' />
                }

                <span className='secondBefore'></span>
            </div>
            <div className='pageTitle'>{title} <span>{titlespan}.</span></div>
            <div className='pageSubText text-center'>
                {text}.
            </div>
        </div>
    )
}

export default PageHeader