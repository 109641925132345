import React, { useState, useEffect } from 'react'
import BannerComponent from '../../Components/BannerComponent/BannerComponent';
import './Causes.scss'
import PageHeader from '../../Components/PageHeader/PageHeader';
import { SiMicrosoftsharepoint } from "react-icons/si";
import Donation from '../../Components/Donation/Donation';

function CausesComponent({ bannerTitle, causesImg, causesTitle, causesText, pageHeaderTitle, pageHeaderTitlespan, causesMainText }) {
    const [donateButton, setDonateButton] = useState(false);

    const donateToggle = () => {
        setDonateButton(true)
    }

    useEffect(() => {
        if (donateButton) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [donateButton]);


    return (
        <div>
            <BannerComponent
                pageTitle={bannerTitle}
                pageName="Detail"
            />

            <div className='causesContainer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <img src={causesImg} alt="" />
                                    <div className='overlay'>
                                        <button className='buttonNav' onClick={donateToggle}>Donate Now</button>
                                    </div>
                                </figure>
                                <div className='lower-content'>
                                    <div className='title'>{causesTitle}</div>
                                    <span></span>
                                    <div className='text'>
                                        {causesText}
                                    </div>
                                    <button className='buttonNav' onClick={donateToggle}>Donate Now</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='rightSided'>
                                <PageHeader
                                    title={pageHeaderTitle}
                                    titlespan={pageHeaderTitlespan}
                                />

                                {causesMainText?.map((data, index) => {
                                    return (
                                        <div className='text' key={index}>
                                            {data.text}
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>

                    <div className='keywordsContainer'>
                        <div className='pageSubTHeading'>Impactful ways we contribute to saving the world</div>
                        <div className='text'>
                            <span><SiMicrosoftsharepoint className='icon' /></span> We Offer or free healthcare services to underserved communities.
                        </div>
                        <div className='text'>
                            <span><SiMicrosoftsharepoint className='icon' /></span> We Conduct campaigns to raise awareness about prevalent health issues.
                        </div>
                        <div className='text'>
                            <span><SiMicrosoftsharepoint className='icon' /></span> We Organize medical missions or camps to provide healthcare services, screenings, and treatments to communities in need.
                        </div>
                        <div className='text'>
                            <span><SiMicrosoftsharepoint className='icon' /></span> We Conduct research to identify health challenges and develop innovative solutions to improve healthcare outcomes.
                        </div>
                        <div className='text'>
                            <span><SiMicrosoftsharepoint className='icon' /></span> We Adopt sustainable practices in healthcare delivery to minimize environmental impact and promote long-term health and well-being.
                        </div>
                        <div className='text'>
                            <span><SiMicrosoftsharepoint className='icon' /></span> We  Advocate for policies that improve access to healthcare, promote health equity, and address social determinants of health.
                        </div>

                    </div>
                </div>

                {donateButton && (
                    <Donation
                        closeToggle={setDonateButton}
                    />
                )}
            </div>
        </div>
    )
}

export default CausesComponent