import React from 'react';
import './Homeabout.scss';
import bigImage from '../../Images/about2.jpg';
import smallImage from '../../Images/about1.jpg'
import { NavLink } from 'react-router-dom';

function HomeAbout() {
    return (
        <div className='HomeAboutSession'>
            <div className="imageSession">
                <div className='bigImage'>
                    <img src={bigImage} alt="" />
                </div>
                <div className='smallImage'>
                    <img src={smallImage} alt="" />
                </div>
            </div>

            <div className='homeAboutContentContainer'>
                <div className='pageSubTHeading'>
                    A Health Charity with Mission
                </div>
                <div className="pageTitle">We are <span>BeulahHealth</span></div>
                <div className='text'>
                    Beulah Health for Life initiative is a community based organization (CBO)
                    that works at the local level whose objective is to promote and to improve
                    the wellbeing of the members of the community.
                </div>
                <div className='text'>
                    We are focussed on health volunteer counselling, bringing health
                    actualization and screening to members of the community, rural
                    health outreaches for everyone in the community, safe water,
                    hygiene and environmental safety.
                </div>

                <div className='transparentButtonNav'>
                    <NavLink to="/about" className='buttonNav'>Read More</NavLink>
                </div>
            </div>
        </div>
    )
}

export default HomeAbout