import React from 'react';
import './Donation.scss';
import { IoMdClose } from "react-icons/io";

function Donation({ closeToggle }) {
    return (
        <div className='DonationContainer'>
            <div className='donationWrapper' onClick={(e) => e.stopPropagation()}>
                <div className='header'>
                    <div className='pageSubTHeading'>Donate Now</div>
                    <button onClick={() => closeToggle(false)}>
                        <IoMdClose className='icon' />
                    </button>
                </div>

                <div className='text'>
                    Your donation can change lives! Help us provide vital healthcare services to those in need by contributing to our cause.
                </div>

                <div className='text'>
                    To make a donation, please use the following account details:
                </div>

                <div className='text'>
                    Account Name: <span>Beulah Health for Life Initiative</span>
                </div>

                <div className='text'>
                    Account Number: <span> 0051776317</span>
                </div>
                <div className='text'>
                    Bank Name: <span>Stanbic IBTC Bank</span>
                </div>
                <div className='text'>
                    Account Type: <span>Naira</span>
                </div>
                <div className='text'>
                    Your generosity can make a real difference in the lives of many.
                </div>

                <div className='text' style={{ color: 'var(--primaryColor)', fontWeight: 'bold' }}>
                    Thank you for your support!
                </div>
            </div>
        </div>
    )
}

export default Donation