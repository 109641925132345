export const TestimonyData = [
    {
        id: 1,
        name: 'Temitope Ola',
        testimony: "Being involved in BeulahHealth has given me the opportunity to contribute meaningfully to initiatives that address critical health issues. The sense of fulfillment I experience when working on projects, whether it's supporting medical outreach programs or raising awareness about preventive care, is unparalleled.",
    },
    {
        id: 2,
        name: 'Abraham Babatunde',
        testimony: "Joining the BeulahHealth NGO was a transformative experience for me. It opened my eyes to the profound impact that collective efforts can have on the well-being of individuals and communities. From participating in health outreach programs to collaborating with dedicated professionals, every moment reinforced the importance of compassion and proactive engagement in creating positive change. ",
    },
    {
        id: 3,
        name: 'TJoy David',
        testimony: "Joining BeulahHealth isn't just about volunteering; it's about becoming part of a movement that believes in the transformative power of collective action. I encourage anyone passionate about healthcare and community well-being to consider joining this impactful organization.",
    }
]