import React from 'react'
import BannerComponent from '../../Components/BannerComponent/BannerComponent'
import './Blog.scss'
import BlogData from '../../Components/BlogData';
import BlogComponent from '../../Components/BlogComponent/BlogComponent';
import BlogRightSide from './BlogRightSide';

function Blog() {
    return (
        <div>
            <BannerComponent
                pageTitle="News Blog"
                pageName="Our Blog"
            />

            <div className="blogContainer">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='blogLeftSidebar'>
                                <div className='row'>
                                    {BlogData?.map((data, index) => {
                                        return (
                                            <div className='col-md-6' key={index}>
                                                <BlogComponent data={data} />
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blogRightSidebar'>
                                <BlogRightSide />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog