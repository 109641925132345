import React from 'react';
import './Home.scss';
import Carousel from '../../Components/Carousel/Carousel';
import HomeAbout from '../../Components/homeAbout/HomeAbout';
import HomeCauses from '../../Components/HomeCauses/HomeCauses';
import { FaLocationArrow } from "react-icons/fa";
import HomeEvent from '../../Components/HomeEvent/HomeEvent';
import MissionGoal from '../../Components/HomeMission/MissionGoal';
import HomeTestimony from '../../Components/HomeTestimony/HomeTestimony';
import ContentCard from '../../Components/contentCard/ContentCard';
import PageHeader from '../../Components/PageHeader/PageHeader';
import BlogData from '../../Components/BlogData';
import BlogComponent from '../../Components/BlogComponent/BlogComponent';
import { NavLink } from 'react-router-dom';

function Home() {
    return (
        <div className='homeContainer'>
            <Carousel />
            {/* another section start here */}
            <ContentCard />

            {/* home about session start here */}
            <HomeAbout />

            {/* home causes starts here */}
            <HomeCauses />


            {/* another section starts here. home Volunteer */}
            <div className='VolunteerSection'>
                <div className='container'>
                    <div className='pageTitle'>Become a <span>volunteer</span></div>
                    <div className='text'>You'll have the opportunity to be part of various projects
                        and initiatives aimed at promoting better health and supporting those in need.
                        Whether you have a background in healthcare or simply
                        a strong desire to help, your commitment can make a significant difference.
                    </div>
                    <div className='subtext'>
                        Interested in making a positive impact on the health and well-being of your community. <br />

                        <NavLink to="/contact" className='buttonNav' >Click here <FaLocationArrow className='icon' /></NavLink>
                    </div>
                </div>
            </div>

            {/* another secton starts here Mission and Goal */}
            <MissionGoal />

            {/* another section starts here home event */}
            <HomeEvent />

            {/* another Section start here. testimony */}
            <HomeTestimony />

            {/* another session starts here */}
            {/* <HomeBlog page="Homepage" /> */}

            <div className='HomeBlog'>
                <PageHeader
                    title="What's"
                    titlespan="Happening"
                    text="A little help accompanied by a gentle smile adds purpose to our existence as humans"
                    currentPage='Home'
                />

                <div className='container' style={{ marginTop: '50px' }}>
                    <div className='row'>
                        {BlogData?.map((data, index) => {
                            return (
                                <div className='col-lg-4 col-md-6' key={index}>
                                    <BlogComponent
                                        data={data}
                                        page="Homepage"
                                    />
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home