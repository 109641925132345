import React from 'react';
import './BannerComponent.scss';
import { FaHome } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

function BannerComponent({ pageTitle, pageName }) {
    return (
        <div className='pageBanner'>
            <div className='container'>
                <div className='page-content'>
                    <h2>{pageTitle}</h2>
                    <p>The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the lives of the poor, the abused and the helpless.</p>
                    <div className='links'>
                        <div className='home'><FaHome className='icon' /><NavLink to="/" className="link" >Home</NavLink></div>
                        <div className='slash'>/</div>
                        <div className='pageName'>{pageName}</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BannerComponent