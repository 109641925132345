import React from 'react'
import causes3 from '../../Images/couses3.jpg';
import './Causes.scss'
import CausesComponent from './CausesComponent';

function Causes1() {

    const dataArray = [
        {
            id: 1,
            text: "BeulahHealth offer medical program for many in undeserved communities who lack access to regular healthcare services. This initiative not only addresses immediate health concerns but also helps prevent and manage chronic conditions through regular check-ups and treatments."
        },
        {
            id: 2,
            text: "However, sustaining such a program requires significant resources, including medical supplies, equipment, and operational costs. BeulahHealth NGO relies on donations to continue providing these crucial services."
        },
        {
            id: 3,
            text: "  Donations help cover the expenses associated with organizing and conducting the free medical camps, ensuring that those in need receive the care they deserve. By supporting BeulahHealth NGO with donations, individuals and organizations can contribute to improving the health and well-being of vulnerable communities."
        },
    ]
    return (
        <div>
            <CausesComponent
                bannerTitle="Free Medical Care"
                causesImg={causes3}
                causesTitle="Free Medical care"
                causesText="Provides a valuable service by offering free medical care. This initiative demonstrates a commitment to community health and well-being, making a significant impact on the lives of those who benefit from these services."
                pageHeaderTitle="Free Medical"
                pageHeaderTitlespan="Care"
                causesMainText={dataArray}
            />
        </div>
    )
}

export default Causes1