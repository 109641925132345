import React from 'react';
import BlogData from '../../Components/BlogData';
import { NavLink } from 'react-router-dom';
import { SiMicrosoftsharepoint } from "react-icons/si";

function BlogRightSide() {
    return (
        <div className='BlogRightSide'>
            <div className=''>
                <div className='pageSubTHeading'>Recent Blog</div>
                {BlogData.map((data, index) => {
                    return (
                        <NavLink to={`/blog/${(data.title).replace(/\s+/g, '-')}`} className='contentWrapper' key={index}>
                            <div className='left'><img src={data.image} alt="" /> </div>
                            <div className='right'>
                                <div className='title'>{data.title}</div>
                                <div className='date'>{data.dateDay}, {data.dateMonth} </div>
                            </div>
                        </NavLink>
                    )
                })}

                <div className='values' style={{ margin: "30px 0px" }}>
                    <div className='pageSubTHeading'>Our Values</div>
                    <div className='listItem'>
                        <span><SiMicrosoftsharepoint className='icon' /></span> Health talks
                    </div>
                    <div className='listItem'>
                        <span><SiMicrosoftsharepoint className='icon' /></span> HIV counselling and testing in communities
                    </div>
                    <div className='listItem'>
                        <span><SiMicrosoftsharepoint className='icon' /></span>Medical outreaches in rural communities
                    </div>
                    <div className='listItem'>
                        <span><SiMicrosoftsharepoint className='icon' /></span> Screening and testing for Tuberculosis in children and adults
                    </div>
                    <div className='listItem'>
                        <span><SiMicrosoftsharepoint className='icon' /></span> Nutrition, Disease surveillance, Roll back Malaria, Breastfeeding
                    </div>
                    <div className='listItem'>
                        <span><SiMicrosoftsharepoint className='icon' /></span>Deworming and Immunization.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogRightSide