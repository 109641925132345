import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home/Home';
import About from '../Pages/About/About';
import Event from '../Pages/Event/Event';
import Contact from '../Pages/Contact/Contact';
import Blog from '../Pages/Blog/Blog';
import BlogDetail from '../Pages/BlogDetail/BlogDetail';
import Causes1 from '../Pages/Causes/Causes1';
import Causes2 from '../Pages/Causes/Causes2';
import Causes3 from '../Pages/Causes/Causes2';

const Router = () => {

    return (
        <Routes>
            <Route exact path="/" caseSensitive={false} element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/event" element={<Event />} />
            <Route exact path='/contact' element={<Contact />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route path='/blog/:title' element={<BlogDetail />} />
            <Route path="/causes/free_medical_care" element={<Causes1 />} />
            <Route path="/causes/sensitization_on_tuberculosis" element={<Causes2 />} />
            <Route path="/causes/sensitization_on_leprosy" element={<Causes3 />} />
        </Routes>
    )
}
export default Router