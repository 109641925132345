import React, { useState } from 'react';
import "./Contact.scss";
import BannerComponent from '../../Components/BannerComponent/BannerComponent';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaPhoneAlt, FaCheck } from "react-icons/fa";
import { MdEmail, MdLocationOn } from "react-icons/md";
import emailjs from "emailjs-com";
import { IoIosAlert } from "react-icons/io";


function Contact() {
    const ref = React.useRef();
    const initialValues = {
        firstName: "",
        lastName: "",
        message: "",
        email: ""
    }
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [loading, setLoading] = useState(false);

    // get input values
    const handleChange = (ev) => {
        setValues({
            ...values,
            [ev.target.name]: ev.target.value,
        });
    };

    const clearValues = () => {
        setValues(initialValues)
        ref.current.reset();
    }
    // handle errors
    const handleError = (targets) => {
        let errorsValue = {};
        if (!targets.firstName) errorsValue.firstName = "Name is required";
        if (!targets.lastName) errorsValue.lastName = "Name is required";
        if (!targets.message) errorsValue.message = "Type  a required";
        if (!targets.email) errorsValue.email = "Email  is required";
        else if (!/\S+@\S+\.\S+/.test(targets.email)) errorsValue.email = "Email is invalid";

        if (Object.keys(errorsValue).length > 0) setErrors({ ...errorsValue });
        else setErrors({});

        return Object.keys(errorsValue).length;

    };

    const submitForm = async (ev) => {
        ev.preventDefault();
        setLoading(true)
        let v = handleError(values);
        setSuccess(false);
        setFailure(false)
        if (v > 0) {
            setLoading(false)
            console.log("error")
        }
        else {
            sendEmail(ev);
            console.log("submitted");
        }
    };
    function sendEmail(ev) {
        emailjs.sendForm(
            'service_y3td85g',
            'template_lwyvgc3',
            ev.target,
            'KIeGCFyRXZjGMCRvI'
        ).then(res => {
            console.log(res);
            setLoading(false)
            setSuccess(true);
            setFailure(false)
            clearValues()
        }).catch(err => {
            console.log(err)
            setSuccess(false);
            setFailure(true)
            setLoading(false)
        })
    }


    return (
        <div>
            <BannerComponent
                pageTitle="Contact Us"
                pageName="Contact"
            />

            <div className='contactPageContainer'>
                <div className='container'>
                    <div className='contactWrapper'>
                        <div className='row'>
                            <div className='col-lg-6 firstColumnContent'>
                                <div className='title'>GET IN TOUCH</div>
                                <div className="text">
                                    The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the lives of the poor, the abused and the helpless.
                                </div>
                                <div className='getinTouch'>
                                    <div className='first'>
                                        <MdLocationOn className='icon' />
                                    </div>
                                    <div className='second'>
                                        <div className='title'>Location</div>
                                        <div className='text'>
                                            No 10 Wiche Lane 1 Off Uniport Road Ndudor Choba Port Harcourt
                                        </div>
                                    </div>
                                </div>
                                <div className='getinTouch'>
                                    <div className='first'>
                                        <MdEmail className='icon' />
                                    </div>
                                    <div className='second'>
                                        <div className='title'>Email</div>
                                        <div className='text'>
                                            <a href="mailto:beulahhealthforlife@gmail.com">Beulahhealthforlife@gmail.com</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='getinTouch'>
                                    <div className='first'>
                                        <FaPhoneAlt className='icon' />
                                    </div>
                                    <div className='second'>
                                        <div className='title'>Phone</div>
                                        <div className='text'>
                                            0803 544 1455 or 07088879779
                                        </div>
                                    </div>
                                </div>
                                <div className='socialUrl'>
                                    <div className='social-link'> <a href="https://facebook.com"><FaFacebookF className='icon' /></a></div>
                                    <div className='social-link'> <a href="https://facebook.com"><FaLinkedinIn className='icon' /></a></div>
                                    <div className='social-link'> <a href="https://facebook.com"><FaTwitter className='icon' /></a></div>
                                </div>
                            </div>
                            <div className='col-lg-6 secondColumnContent'>
                                <div className="Wrapper">
                                    <div className='title'>
                                        DROP US A LINE
                                    </div>
                                    <div className="text">
                                        Send us a message and be sure our team will respond to you.
                                    </div>


                                    <form className='formContainer' onSubmit={submitForm} ref={ref}>
                                        {
                                            success && <div className='message'>
                                                <div className='success'>

                                                    <span><FaCheck className='icon' /></span> Form submitted successfully
                                                </div>
                                            </div>
                                        }

                                        {
                                            failure && <div className='message'>
                                                <div className='failed'>
                                                    <span><IoIosAlert className='icon' /></span> !oops, Form not submitted
                                                </div>
                                            </div>
                                        }
                                        <div className="content">
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-6'>
                                                    <input type="text" name="firstName" className={errors && errors.firstName ? 'inputText errorBorder' : 'inputText'} onChange={handleChange} placeholder='FirstName' />
                                                    {errors ? <p> {errors.firstName}</p> : ""}
                                                </div>
                                                <div className='col-lg-6 col-md-6'>
                                                    <input type="text" name="lastName" className={errors && errors.lastName ? 'inputText errorBorder' : 'inputText'} onChange={handleChange} placeholder='LastName' />
                                                    {errors ? <p> {errors.lastName}</p> : ""}
                                                </div>
                                                <div className='col-lg-12 col-md-12'>
                                                    <input type="email" name="email" className={errors && errors.email ? 'inputText errorBorder' : 'inputText'} onChange={handleChange} placeholder='Email' />
                                                    {errors ? <p> {errors.email}</p> : ""}
                                                </div>
                                                <div className='col-lg-12 col-md-12'>
                                                    <textarea name="message" className={errors && errors.message ? 'textarea errorBorder' : 'textarea'} placeholder='Message' onChange={handleChange} rows="6" />
                                                    {errors ? <p> {errors.message}</p> : ""}
                                                </div>
                                                <div className=''>
                                                    {loading ? <>
                                                        <button className="buttonNav" >
                                                            <span className="spinner-grow spinner-grow-sm text-info" role="status" style={{ marginRight: '10px' }}>
                                                            </span>
                                                            Submitting...
                                                        </button>
                                                    </> :
                                                        <button className="buttonNav" >Submit Now</button>
                                                    }
                                                </div>
                                            </div>

                                            {
                                                success && <div className='message'>
                                                    <div className='success'>

                                                        <span><FaCheck className='icon' /></span> Form submitted successfully
                                                    </div>
                                                </div>
                                            }

                                            {
                                                failure && <div className='message'>
                                                    <div className='failed'>
                                                        <span><IoIosAlert className='icon' /></span> !oops, Form not submitted
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact