import React from 'react';
import { FaHandsHelping } from "react-icons/fa";
import './ContentCard.scss'
import icon1 from '../../Images/heart.JPG'
import icon2 from '../../Images/donate.JPG'
import icon3 from '../../Images/boy.JPG'
import icon4 from '../../Images/donate2.JPG'

function ContentCard() {
    return (
        <div className='contentCardContainer'>
            <div className='container'>
                <div className="row">
                    <div className="homeServices col-lg-3 col-md-6 col-sm-6">
                        <div className="inner-box hvr-float-shadow">
                            <div className="icon"><span className="flaticon"><img src={icon1} alt="" /> </span></div>
                            <h4>Get Inspired</h4>
                            <div className="text">Every act of kindness, no matter how small, makes a difference in someone's life. </div>
                            <div className='helping'><FaHandsHelping className='icon' /></div>
                        </div>
                    </div>
                    <div className="homeServices col-lg-3 col-md-6 col-sm-6">
                        <div className="inner-box hvr-float-shadow">
                            <div className="icon"><span className="flaticon"><img src={icon2} alt="" /> </span></div>
                            <h4>Give Donation</h4>
                            <div className="text"> Every contribution, no matter the size, impact someone's life positively.</div>
                            <div className='helping'><FaHandsHelping className='icon' /></div>
                        </div>
                    </div>
                    <div className="homeServices col-lg-3 col-md-6 col-sm-6">
                        <div className="inner-box hvr-float-shadow">
                            <div className="icon"><span className="flaticon"><img src={icon3} alt="" /> </span></div>
                            <h4>Become a Volunteer</h4>
                            <div className="text">Your time and effort can bring change to communities facing challenges.</div>
                            <div className='helping'><FaHandsHelping className='icon' /></div>
                        </div>
                    </div>
                    <div className="homeServices col-lg-3 col-md-6 col-sm-6">
                        <div className="inner-box hvr-float-shadow">
                            <div className="icon"><span className="flaticon"><img src={icon4} alt="" /> </span></div>
                            <h4>Help The Children</h4>
                            <div className="text"> Your assistance can brighten their days and make a lasting difference.</div>
                            <div className='helping'><FaHandsHelping className='icon' /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentCard