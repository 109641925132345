import React from 'react';
import './HomeTestimony.scss';
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { TestimonyData } from '../../Pages/Data/TestimonyData';
import { MdPerson } from "react-icons/md";

function HomeTestimony() {
    return (
        <div className='HomeTestimonyContainer'>
            <div className='container'>
                <OwlCarousel items={1}
                    className="owl-carousel owl-theme"
                    loop
                    nav
                    margin={8}
                    dots={true}
                    mouseDrag={false}
                    autoplay
                    lazyLoad={true}
                    autoplayTimeout={5000}
                    animateOut={'animate__animated animate__fadeInUp'}
                >
                    {TestimonyData.map((data, index) => (
                        <div className='contentWrapper' key={index}>
                            <div className='imageSession'>
                                <span><RiDoubleQuotesL className='icon' /></span>
                                <div className='image'>
                                    <MdPerson className='icon' />
                                </div>
                                <span><RiDoubleQuotesR className='icon' /> </span>
                            </div>
                            <div className='content'>
                                <div className='testimony'>{data.testimony}</div>
                                <div className='name'>{data.name}</div>
                            </div>
                        </div>
                    ))}

                </OwlCarousel>
            </div>

        </div>
    )
}

export default HomeTestimony