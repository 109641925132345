import React from 'react'
import './Event.scss';
import BannerComponent from '../../Components/BannerComponent/BannerComponent';
import { eventData } from '../../Pages/Data/EventData';
import { MdOutlineAccessTimeFilled, MdLocationPin } from "react-icons/md";

function Event() {
    return (
        <div>
            <BannerComponent
                pageTitle="Charity Events"
                pageName="Event"
            />
            <div className='eventContainer causesContainer'>
                <div className='container'>
                    <div className='row'>
                        {eventData.sort((a, b) => (b.id) - (a.id)).map((data, index) => {
                            return (
                                <div className='col-lg-6 ' key={index}>
                                    <div className='inner-box'>
                                        <div className='lower-content'>
                                            <div className='title'>{data.title}</div>
                                            <span></span>

                                            <div className='wrapper'>
                                                <div className='dateWrapper'>25 <div className='month'>July</div></div>
                                                <div className='others'>
                                                    <div className='organise'>Organised By: <div className='beulah'>Beulah Health</div></div>
                                                    <div className='timeLocation'>
                                                        <div className='first'>
                                                            <MdOutlineAccessTimeFilled className='icon' />
                                                            Starts 5.00PM
                                                        </div>
                                                        <div className='second'>
                                                            <MdLocationPin className='icon' />
                                                            {data.location}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Event