import React from 'react'
import causesimg from '../../Images/courses1.jpg';
import './Causes.scss'
import CausesComponent from './CausesComponent';

function Causes3() {

    const dataArray = [
        {
            id: 1,
            text: "BeulahHealth NGO took a multifaceted approach to sensitize the community about tuberculosis (TB). We  conducted awareness campaigns in schools, workplaces, and communities, educating people about TB transmission, symptoms, and prevention."
        },
        {
            id: 2,
            text: "We distributed informational materials and held interactive sessions to dispel myths and stigma associated with the disease. BeulahHealth also collaborated with local health authorities to provide free TB screenings and treatment for those in need. "
        },
        {
            id: 3,
            text: "Additionally, We trained healthcare workers to improve TB diagnosis and management. Through these efforts, BeulahHealth NGO significantly raised awareness about TB, leading to early detection and improved treatment outcomes in the community."
        },
    ]
    return (
        <div>
            <CausesComponent
                bannerTitle="Sensitization on Tuberculosis"
                causesImg={causesimg}
                causesTitle="Sensitization on Tuberculosis"
                causesText="Embarked on a comprehensive tuberculosis (TB) sensitization campaign to raise awareness about the disease and its prevention. "
                pageHeaderTitle="Sensitization on"
                pageHeaderTitlespan="Tuberculosis"
                causesMainText={dataArray}
            />
        </div>
    )
}

export default Causes3