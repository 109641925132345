import React from 'react';
import './MisionGoal.scss'
import { FaHandsHelping } from "react-icons/fa";
import misionIcon1 from '../../Images/mission1.JPG'
import misionIcon2 from '../../Images/mission2.JPG'
import misionIcon3 from '../../Images/mission3.JPG'
import PageHeader from '../PageHeader/PageHeader';

function MissionGoal() {
    return (
        <div className='MissionGoalContainer'>
            <div className='container'>
                <PageHeader
                    title="Mission &"
                    titlespan='Goals'
                    text="A little help accompanied by a gentle smile adds purpose to our existence as humans"
                    currentPage="Home"
                />

                <div className='row'>
                    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 mission-block'>
                        <div className='inner-box'>
                            <div className='image'>
                                <div className='caption'>
                                    <div>
                                        <img src={misionIcon1} alt="" />
                                        <h4>Medical Facilities</h4>
                                    </div>
                                </div>
                                <div className='overlay-box'>
                                    <div className='overlay-inner'>
                                        <div className='content'>
                                            <div className='text'>
                                                Screening for children age 0-14 years during the TB childhood
                                                testing week with lots of children in various communities screened,
                                                sampled stool and sputum.
                                            </div>
                                            <h4>Medical Facilities</h4>
                                            <span>BeulahHealth</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 mission-block'>
                        <div className='inner-box'>
                            <div className='image'>
                                <div className='caption'>
                                    <div>
                                        <img src={misionIcon2} alt="" />
                                        <h4>Education For All</h4>
                                    </div>
                                </div>
                                <div className='overlay-box'>
                                    <div className='overlay-inner'>
                                        <div className='content'>
                                            <div className='text'>
                                                Sensitization on health Issues
                                                Health talks in churches and schools on how to prevent the spread of
                                                leprosy.
                                            </div>
                                            <h4>Education For All</h4>
                                            <span>BeulahHealth</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 mission-block'>
                        <div className='inner-box'>
                            <div className='image'>
                                <div className='caption'>
                                    <div>
                                        <img src={misionIcon3} alt="" />
                                        <h4>Love Your World</h4>
                                    </div>
                                </div>
                                <div className='overlay-box'>
                                    <div className='overlay-inner'>
                                        <div className='content'>
                                            <div className='text'>
                                                Love your world, for it is the nurturing embrace of our planet that sustains us and inspires the beauty within our existence.
                                            </div>
                                            <h4>Love Your World</h4>
                                            <span>BeulahHealth</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MissionGoal