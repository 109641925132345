import React, { useState, useEffect } from 'react';
import "./Navbar.scss";
import { NavLink } from 'react-router-dom';
import { MdOutlineArrowDropDown, MdCall, MdEmail, MdClose } from "react-icons/md"
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from '../../Images/logo.jpeg'
import Donation from '../Donation/Donation';

function Navbar() {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click);

    // const [showdropdown, setshowdropdown] = useState(false)
    // const handleshowdropdown = () => setshowdropdown(!showdropdown);

    // const handleKeyUp = () => {
    //     setshowdropdown(true)
    // }
    const [scrolled, setScrolled] = useState(false);
    const changeBackground = () => {
        // the 200 below means where you want the background to change when scroll
        // it is advisable to put the background at the height of the navbar
        if (window.scrollY >= 50) {
            setScrolled(true);
        } else {
            setScrolled(false)
        }
    }
    window.addEventListener('scroll', changeBackground)

    const [donateButton, setDonateButton] = useState(false);

    const donateToggle = () => {
        setDonateButton(true)
    }

    useEffect(() => {
        if (donateButton) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [donateButton]);


    return (
        <div>
            <div className="topNavbar">
                <div className='container'>
                    <div className='left'>
                        <div className="content">
                            <div className='icondiv'><MdCall className='icon' /></div>
                            <div className='text'>Call Us Now <span>0803 544 1455</span></div>
                        </div>
                        <div className="content">
                            <div className='icondiv'><MdEmail className='icon' /></div>
                            <div className='text'>Email us <span><a href="mailto:beulahhealthforlife@gmail.com">Beulahhealthforlife@gmail.com</a></span></div>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='socialMedia'>
                            <a href="https://facebook.com"><FaFacebookF className='icon' /></a>
                            <a href="https://facebook.com"><FaLinkedinIn className='icon' /></a>
                            <a href="https://facebook.com"><FaTwitter className='icon' /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={scrolled ? 'navbar scrolled' : 'navbar'}>
                <div class="container">
                    <div className='navbar-container'>
                        <div className="smallDeviceTop">
                            <div className='image'>
                                <NavLink to="/" exact ><img src={Logo} alt="" /></NavLink>
                            </div>
                            <div className="nav-icon" onClick={handleClick}>
                                {click === true ? <div>
                                    <span className="" onClick={handleClick} > <MdClose className="nav-cancel" />   </span>
                                </div> : <div>
                                    <span className="" onClick={handleClick} > <AiOutlineMenu />   </span>
                                </div>
                                }
                            </div>
                        </div>


                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <NavLink to="/" exact className="nav-link" onClick={handleClick}>Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/about" className="nav-link" onClick={handleClick}>About Us</NavLink>
                            </li>
                            <li className="nav-item">
                                <div className="nav-link navlinkDrop" style={{ cursor: 'pointer' }}>Causes <MdOutlineArrowDropDown className='navlink-icon' /></div>
                                <div className="nav-dropdown">
                                    <NavLink to="/causes/sensitization_on_tuberculosis" className="nav-dropdown-nav" onClick={handleClick}>Sensitization on Tuberculosis</NavLink>
                                    <NavLink to="/causes/sensitization_on_leprosy" className="nav-dropdown-nav" onClick={handleClick}>Sensitization on Leprosy</NavLink>
                                    <NavLink to="/causes/free_medical_care" className="nav-dropdown-nav" onClick={handleClick}>Free Medical care</NavLink>
                                </div>
                                {/* {
                                    showdropdown === true ? (
                                        <div className="nav-dropdown">
                                            <NavLink to="/tuberculosis" className="nav-dropdown-nav" onClick={handleClick}>Sensitization on Tuberculosis</NavLink>
                                            <NavLink to="/leprosy" className="nav-dropdown-nav" onClick={handleClick}>Sensitization on Leprosy</NavLink>
                                            <NavLink to="/medical_treatment" className="nav-dropdown-nav" onClick={handleClick}>Free Medical care</NavLink>
                                        </div>
                                    ) : null
                                } */}


                            </li>
                            <li className="nav-item">
                                <NavLink to="/event" className="nav-link" onClick={handleClick}>Events</NavLink>

                            </li>
                            <li className="nav-item">
                                <NavLink to="/blog" className="nav-link" onClick={handleClick}>Blog</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link" onClick={handleClick}>Contact</NavLink>
                            </li>

                        </ul>

                        <div className='rightHanded'>
                            <div className='navDonate'><button className="buttonNav" onClick={donateToggle}> Donate Now</button></div>
                        </div>
                    </div>
                </div>
            </div>

            {donateButton && (
                <Donation
                    closeToggle={setDonateButton}
                />
            )}
        </div>
    )
}

export default Navbar 