import React from 'react';
import './BlogDetail.scss';
import { useParams } from 'react-router-dom';
import BannerComponent from '../../Components/BannerComponent/BannerComponent';
import BlogData from '../../Components/BlogData';
import Blog from '../Blog/Blog';
import BlogComponent from '../../Components/BlogComponent/BlogComponent';
import BlogRightSide from '../Blog/BlogRightSide';


function BlogDetail() {
    const { title } = useParams();
    const singleBlog = BlogData.find(data => ((data?.title).replace(/\s+/g, '-')) === title)
    console.log('ID', singleBlog?.id)


    return (
        <div>
            <BannerComponent
                pageTitle="Blog Detail"
                pageName="Detail"
            />

            <div className=''>
                <div className='BlogDetailComponent'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='detailLeftSidebar' key={singleBlog?.id}>
                                    <BlogComponent
                                        data={singleBlog}
                                        page="BlogDetail"
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='detailRightSidebar'>
                                    <BlogRightSide />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BlogDetail